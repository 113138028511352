import React from "react";
import Template from '../components/Template/Template';
// import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Marki = ({ intl }) => {

    return (
        <Template path="/marki">
           
           <div className="mWidth">
               <h1 className="ch1">Marki skupowanych tonerów</h1>
               <section id="pSell" className="ccon">
                    <p>W szufladzie zalega produkt marki Canon, HP, Lexmark czy Minolta? A może w firmowym magazynie odkryli Państwo nie otawarte pudełko od Samsunga albo Dell? Jeśli mają Państwo wątpliwości, czy skup pełnych tonerów przyjmie Wasz produkt, tu znajdziecie odpowiedź na Wasze pytanie.</p>

                    <p> Od razu uspokajamy: odbierzemy od Państwa wszystkie oryginalne kartridże. Przyjmujemy i egzemplarze od najbardziej popularnych marek, i te od firm, które są mniej znane na polskim rynku. Można więc sprzedać do skupu pełne tonery Panasonic i Ricoh, ale także IBM, Brother czy Toshiby. </p>

                    <p> Jeśli więc posiadają Państwo oryginalny kartridż z nieuszkodzoną plombą – zachęcamy do kontaktu! Nasz kurier odbierze je od Was w każdym mieście, czy to Koszalin, Ruda Śląska, Szczecin czy inne.</p>

                    <p>
                        <b><a href="/marka,canon.html" className="kfa">Canon</a></b>
                        <br />
                        Kupili Państwo zapas tonerów Canon, ale jednak się on nie przydał? U nas możecie je łatwo sprzedać!
                        <br />
                        <a href="/marka,canon.html" className="kfa">Czytaj więcej >></a>

                    </p>
                    <p>
                        <b><a href="/marka,brother.html" className="kfa">Brother</a></b><br />
                        W Warszawie nasz kierowca odbierze od Was oryginalne, pełne kartridże Brother – nie muszą Państwo nic wysyłać.
                        <br />
                        <a href="/marka,brother.html" className="kfa">Czytaj więcej >></a>
                    </p>
                    <p>
                        <b><a href="/marka,hp.html" className="kfa">HP</a></b><br />
                        Jeśli w Państwa firmie zalegają produkty marki HP, których nie zamierzają Państwo wykorzystać, zachęcamy do kontaktu!
                        <br /><a href="/marka,hp.html" className="kfa">Czytaj więcej >></a>
                    </p>
                    <p>
                        <b><a href="/marka,samsung.html" className="kfa">Samsung</a></b><br />
                        Skupujemy kartridże Samsunga –  w pudełkach, które nigdy nie zostały naruszone.
                        <br /><a href="/marka,samsung.html" className="kfa">Czytaj więcej >></a>
                    </p>
                    <p>
                        <b><a href="/marka,minolta.html" className="kfa">Minolta</a></b><br />
                        Zamiast wyrzucać oryginalny toner Minolta, zgłoście się do nas. Tylko w ten sposób odzyskacie część wydanych pieniędzy.
                        <br />
                        <a href="/marka,minolta.html" className="kfa">Czytaj więcej >></a>
                    </p>
                    <p>
                        <b><a href="/marka,xerox.html" className="kfa">Xerox</a></b><br />
                        Pełen wkład Xerox, którego nie potrzebujecie? Możecie sprzedać go do naszego skupu – z pożytkiem dla portfela.
                        <br /><a href="/marka,xerox.html" className="kfa">Czytaj więcej >></a>
                    </p>
                    <p>
                        <b>Lexmark</b><br />
                        Prowadzimy skup oryginalnych tonerów producenta Lexmark – przyjmujemy także te w starszych pudełkach.
                    </p>
                    <p>
                        <b>Ricoh</b><br />
                        Pełny produkt od marki Ricoh, który okazał się niepotrzebny? Chętnie go od Państwa odkupimy!
                    </p>
                    <p>
                        <b>OKI</b><br />
                        Szczecin, Ruda Śląska czy Koszalin – wysyłamy kurierów do każdego miasta Polski po odbiór produktów OKI.
                    </p>
                </section>
            </div>
    

        </Template>
    )
}

// export default Marki
export default Marki